<template>
    <div>
        <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent="" scrollable  max-width="730px">
                <v-card :style="($vuetify.breakpoint.mdAndDown) ? 'width:auto;' : 'min-width:320px;'">
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: auto;">
                        <div style="min-height:10px;"></div>
                         <v-container grid-list-md>
                             <v-layout row wrap>
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Sales location:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ (("sales_location" in item)) ? item.sales_location.name : "N/A" }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Sales location number:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.sales_location_nr }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Cashier number:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.cashier_nr }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Invoice timestamp:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.date_time }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Fiscalization timestamp:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.verified_timestamp }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Payment type:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.payment_type }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Amount:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.amount }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Tax base:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.tax_base }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Tax amount:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.tax_amount }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">ZKI:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small>{{ item.zoi }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">JIR:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small>{{ item.eor }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Card payment:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small :color="cardPaymentColor(item.card_payment)">{{ (item.card_payment !== null && item.card_payment == 1) ? 'YES' : 'NO' }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Payment method:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small :color="paymentMethodColor(item.payment_method)">{{ paymentMethodName(item.payment_method) }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Transaction ID:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small>{{ item.transaction_id }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">UUID:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ item.uuid }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <div v-if="item.sqs == 1 && item.sqs_payload != null && item.sqs_payload !== ''">

                                    <div style="min-height:30px"></div>

                                    <v-flex xs12 sm12 md12 lg12>
                                        <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Sqs timestamp: {{ item.sqs_timestamp }}</span>
                                    </v-flex>

                                    <v-flex xs12 sm12 md6 lg6>
                                        <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Sqs payload:</span>
                                    </v-flex>
                                    <div style="min-height:10px;"></div>
                                        <json-viewer
                                        :value="item.sqs_payload"
                                        :expand-depth="10"
                                        copyable
                                        boxed
                                        expanded
                                        sort></json-viewer>
                                    
                                </div>

                                <div v-if="item.sqs_transaction == 1 && item.sqs_transaction_payload != null && item.sqs_transaction_payload !== ''">

                                    <div style="min-height:30px"></div>

                                    <v-flex xs12 sm12 md12 lg12>
                                        <v-icon left></v-icon><span style="color:#000;">Sqs transaction timestamp: {{ item.sqs_transaction_timestamp }}</span>
                                    </v-flex>

                                    <v-flex xs12 sm12 md6 lg6>
                                        <v-icon left></v-icon><span style="color:#000;">Sqs transaction payload:</span>
                                    </v-flex>
                                    <div style="min-height:10px;"></div>
                                        <json-viewer
                                        :value="item.sqs_transaction_payload"
                                        :expand-depth="10"
                                        copyable
                                        boxed
                                        expanded
                                        sort></json-viewer>
                                    
                                </div>
                                
                            </v-layout>
                         </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-btn color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
    </div>
</template>

<script>
import { formatDate, randomPinNumber, getCurrentDate } from '@/helpers/utilities.js'
import { EventBus } from '@/main.js'

export default {

    name: 'invoice-info-dialog',
    data: () => ({
        valid: false,
        item: {},
        dialog: false,
        loading: false,
        note: null,
        formTitle: "",
        cancelText: 'Close',
        event_timestamp: null,
        currentDate: null,

        
    }),

    watch : {

    },

    computed: {

    },

    methods: {
        setFormTitle() {
            this.formTitle = "Invoice number #" + this.item.invoice_nr
        },

        cancel() {
            this.dialog = false
            this.item = {}
        },

        paymentMethodName(paymentMethodId) {
            if(paymentMethodId == 1) {
                return "CREDIT CARD";
            }

            if(paymentMethodId == 2) {
                return "FREE VEND";
            }

            if(paymentMethodId == 3) {
                return "CASH"
            }

            return ""
        },

        paymentMethodColor(paymentMethod) {
           if(paymentMethod == 1) {
               return "error"
           }

           if(paymentMethod == 2) {
               return "#6D4C41"
           }

           return "success";
       },

       cardPaymentColor(cardPayment) {
           if(cardPayment == 1) {
               return "success"
           }

           return "error"
       }
    },

    created() {
        var vm = this;
        this.currentDate = getCurrentDate()

        EventBus.$on('VIEW_INVOICE_ITEM_DIALOG', function(data) {
            //window.console.log(data)
            vm.item = data
            vm.event_timestamp = vm.item.event_timestamp_formatted
            vm.setFormTitle();
            vm.dialog = true;
        });
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off('VIEW_INVOICE_ITEM_DIALOG')
    },

    destroyed() {

    }

}

</script>

<style scoped>
    .v-card__title {
        background-color: #f5f5f5 !important;
    }

    span.detail-name-label {
        color: "#000" !important;
    }
</style>